function swerkAlert()
{
    if($(".swerk-alert").length) {
        var delayIn = 0;
        var delayOut = 6000;
        $('.swerk-alert').each(function(i){
            var notification = $(this);
            setTimeout(function(){
                notification.addClass('active')
            }, delayIn);
            if(!notification.hasClass('alert-important')){
                setTimeout(function(){
                    notification.removeClass('active')
                }, delayOut);
            }
            delayIn = delayIn + 100;
            delayOut = delayOut + 100;
        });
    }
}