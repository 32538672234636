function add_js_input(form_id){
    $('<input>').attr({
        type: 'hidden',
        id: 'captcha',
        name: 'captcha',
        value: 'honey'}).appendTo(form_id);
}
$(document).ready(function(){

    if(window.validate_contact_form){
		//validate_contact_form();
		add_js_input('#contact_form');
	}

});
$(window).on('load', function () {

    save_run_js_function('swerkCarousel');
    save_run_js_function('swerkAlert');
    $( "li.nav-item.active" ).parents().addClass('active');
    $('.lazy').lazy({
        scrollDirection: 'vertical',
        effect: 'fadeIn',
        effectTime: 0,
        threshold: 0,
        visibleOnly: true,
        onError: function(element) {
            console.log('error loading ' + element.data('src'));
        }
    });

    $('.datepicker').datepicker({
        format: 'dd-mm-yyyy',
        startDate: 'today'
    });

    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

});
