$('.custom-file-input').on('change',function(e){
    readURL(this);
});
function readURL(input) {
    var inputLocal = input;
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('#avatarImg').attr('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
}